<template>
  <AntSpin :spinning="spinVisible">
    <div class="header">
      <AntSpace>
        <AntDropdown>
          <template #overlay>
            <AntMenu @click="handleMenuDocClick" :selectedKeys="selectedMenuDocKeys">
              <template v-for="walletType in Object.keys(apiDocList)" >
                <AntMenuItem
                  v-for="edition in apiDocList[walletType]"
                  :key="getMenuDocTitle(walletType, edition)"
                >
                  {{ getMenuDocTitle(walletType, edition) }}
                </AntMenuItem>
              </template>
            </AntMenu>
          </template>
          <AntButton>
            {{ selectedMenuDocKeys[0] }}
            <ArrowDownIcon />
          </AntButton>
        </AntDropdown>

        <a
          class="selected-doc-link"
          :href="selectedDocLink"
          target="_blank"
        >{{ selectedDocLink }}</a>
      </AntSpace>

      <AntSpace>
        <AntButton v-if="separateWindow" @click="handleToggleSeparateWindow">
          <template #icon>
            <SplitViewIcon />
          </template>
          Split View
        </AntButton>
        <AntButton v-else @click="handleToggleSeparateWindow">
          <template #icon>
            <SeparateWindowIcon />
          </template>
          Separate Window
        </AntButton>
        <AntButton @click="handleDownload">Download</AntButton>
        <AntButton type="primary" @click="handlePublish" :disabled="spinVisible || isEditing">Publish</AntButton>
      </AntSpace>
    </div>

    <div class="openapi-editor">
      <div :class="['editor-wrapper', isEditing && 'readonly', separateWindow && 'full-width']">
        <Codemirror
          :value="editorValue"
          :options="{ readOnly: !isDev && isEditing }"
          @change="handleEditorChange"
          @blur="handleEditorBlur"
        />
      </div>
      <div
        ref="previewWrapperRef"
        :class="['preview-wrapper', separateWindow && 'hide']"
      >
        <iframe
          ref="iframeRef"
          class="redoc-previewer"
          src="/redoc-previewer"
        />
        <div ref="previewerWinRef"></div>
      </div>
    </div>

    <AntModal
      :visible="modalVisible"
      @cancel="handleCloseModal"
    >
      <div class="ant-modal-confirm-confirm">
        <div class="ant-modal-confirm-body">
          <ExclamationIcon />
          <span class="ant-modal-confirm-title">{{ `您尚未發佈 ${selectedMenuDocKeys} 的變更，請問要先發佈嗎？` }}</span>
        </div>
      </div>
      <template #footer>
        <AntButton key="go" @click="handleSelectDoc">否，直接跳轉</AntButton>
        <AntButton key="submit" type="primary" @click="handlePublishAndSelectDoc">是，發佈變更</AntButton>
      </template>
    </AntModal>
  </AntSpin>
</template>

<script>
import {
  ref,
  computed,
  markRaw,
  onMounted,
  onUnmounted,
  nextTick,
} from 'vue'

import Split from 'split.js'

import ArrowDownIcon from '@ant-design/icons-vue/lib/icons/DownOutlined'
import SeparateWindowIcon from '@ant-design/icons-vue/lib/icons/SelectOutlined'
import SplitViewIcon from '@ant-design/icons-vue/lib/icons/MergeCellsOutlined'
import ExclamationIcon from '@ant-design/icons-vue/lib/icons/ExclamationCircleOutlined'

import {
  Spin as AntSpin,
  Space as AntSpace,
  Menu as AntMenu,
  Button as AntButton,
  Modal as AntModal,
  Dropdown as AntDropdown,
  Message as antMessage,
} from 'ant-design-vue'

import Codemirror from '@/components/Codemirror'

import { fetchApiDoc, updateApiDoc } from '@/api/apiDoc'

export default {
  name: 'OpenApiEditor',
  components: {
    AntSpin,
    AntSpace,
    AntMenu,
    AntMenuItem: AntMenu.Item,
    AntButton,
    AntModal,
    AntDropdown,
    Codemirror,
    ArrowDownIcon,
    SeparateWindowIcon,
    SplitViewIcon,
    ExclamationIcon,
  },
  setup() {
    // const isDev = false
    const isDev = process.env.NODE_ENV === 'development'

    const editorValue = ref('')
    const initialEditorValue = ref('')
    const lastEditorValue = ref('')
    const isEditing = ref(false)

    const selectedMenuDocKeys = ref(['Single Wallet - PRD'])
    const selectedDoc = ref('Single Wallet - PRD')
    const spinVisible = ref(false)
    const modalVisible = ref(false)

    const separateWindow = ref(false)
    const previewWrapperRef = ref()
    const splitIns = ref()

    const iframeRef = ref()
    const iframeWin = ref()
    const previewerWinRef = ref()
    const previewerWinObj = ref()
    const apiDocWinRef = ref()

    const handleUpdateApiDoc = async (value) => {
      const keyArr = selectedMenuDocKeys.value[0].split(' - ')
      const res = await updateApiDoc({
        walletType: keyArr[0].toLowerCase().replace(/ wallet/, ''),
        edition: keyArr[1],
        ...value,
      })
      return res
    }

    const getApiDoc = async () => {
      antMessage.destroy()

      const arr = selectedDoc.value.split(' - ')
      arr[0] = arr[0].toLowerCase().replace(/ wallet/, '')

      const res = await fetchApiDoc({ wallet: arr[0], edition: arr[1] })

      const { content } = res.data
      editorValue.value = content
      initialEditorValue.value = content
      lastEditorValue.value = content

      if (!isDev && !res.data.isEditing) {
        await handleUpdateApiDoc({ isEditing: true })
      } else if (res.data.isEditing) {
        antMessage.warning({
          key: new Date(),
          content: 'Someone else is editing...',
          duration: 0,
        })
      }

      isEditing.value = !!res.data.isEditing
    }

    const handlePublish = async () => {
      const messageKey = 'updateApiDoc'
      antMessage.loading('', { key: messageKey })

      const res = await handleUpdateApiDoc({ content: editorValue.value })
      const { status, message } = res

      if (status === 'success') {
        antMessage.success({
          key: messageKey,
          content: `Published ${selectedMenuDocKeys.value[0]} successfully`,
        })

        initialEditorValue.value = editorValue.value
      } else {
        antMessage.error({
          key: messageKey,
          content: `Failed to publish ${selectedMenuDocKeys.value[0]}: ${message}`,
        })
      }
    }

    const sendMessageToPreviewerWindow = (e, scrollToTop) => {
      if (separateWindow.value) {
        previewerWinObj.value.postMessage({
          openApiEditorValue: editorValue.value,
          scrollToTop,
        }, '*')
      } else {
        iframeWin.value.postMessage({
          openApiEditorValue: editorValue.value,
          scrollToTop,
        }, '*')
      }
    }

    const apiDocList = {
      single: ['PRD', 'AWSKR1'],
      multi: ['PRD', 'IGK', 'WinBox', 'AWSKR1'],
    }

    const selectedDocLink = computed(() => {
      const arr = selectedDoc.value.split(' - ')
      arr[0] = arr[0].toLowerCase().replace(' ', '-')
      return `${window.location.origin}/api-doc/${arr[0]}/${arr[1]}`
    })

    const getMenuDocTitle = (walletType, edition) => `${walletType.replace(/\b\w/g, l => l.toUpperCase())} Wallet - ${edition}`

    const handleCloseModal = () => modalVisible.value = false

    const handleSelectDoc = async () => {
      if (!isDev && !isEditing.value) await handleUpdateApiDoc({ isEditing: false })

      spinVisible.value = true
      const key = selectedDoc.value
      selectedMenuDocKeys.value = [key]
      window.location.hash = ''

      await getApiDoc()
      sendMessageToPreviewerWindow(null, true)
      handleCloseModal()
      spinVisible.value = false
    }

    const handleMenuDocClick = ({ key }) => {
      selectedDoc.value = key

      if (initialEditorValue.value !== editorValue.value) {
        modalVisible.value = true
      } else {
        handleSelectDoc()
      }
    }

    const handlePublishAndSelectDoc = () => {
      handlePublish()
      handleSelectDoc()
    }

    const openSeparateWindow = () => {
      previewerWinRef.value = window.open(
        '/redoc-previewer',
        'winname',
        'titlebar=no,toolbar=no,location=no,width=1400,height=700,top=200,left=200',
      )
      previewerWinRef.value.addEventListener('load', sendMessageToPreviewerWindow)
      previewerWinObj.value = previewerWinRef.value
    }

    const handleToggleSeparateWindow = () => {
      separateWindow.value = !separateWindow.value
      if (separateWindow.value) {
        openSeparateWindow()
        splitIns.value.destroy()
        window.location.hash = ''
      } else {
        splitIns.value = markRaw(Split(['.editor-wrapper', '.preview-wrapper'], { sizes: [35, 65] }))
      }
    }

    const handleDownload = () => {
      apiDocWinRef.value = window.open(
        selectedDocLink.value,
        'winname',
        'titlebar=no,toolbar=no,location=no,width=900,height=650,top=200,left=200',
      )
    }

    const handleEditorChange = val => editorValue.value = val

    const handleEditorBlur = (val) => {
      if (lastEditorValue.value !== val) {
        lastEditorValue.value = val
        sendMessageToPreviewerWindow()
      }
    }

    const handleMessage = ({ data: { isPreviewerReady, isRedocLoaded } }) => {
      if (isPreviewerReady) sendMessageToPreviewerWindow()
      if (isRedocLoaded) {
        setTimeout(() => {
          // Auto click "Shell - Curl" language
          const apiDocWindow = apiDocWinRef.value
          const langTabs = apiDocWindow.document.querySelectorAll('li.react-tabs__tab')
          Array.prototype.filter.call(langTabs, el => RegExp('Shell - Curl').test(el.textContent)).forEach(el => el.click())

          apiDocWindow.focus()
          apiDocWindow.print()
          apiDocWindow.close()
        }, 500)
      }
    }

    onMounted(async () => {
      spinVisible.value = true
      iframeWin.value = iframeRef.value.contentWindow
      splitIns.value = markRaw(Split(['.editor-wrapper', '.preview-wrapper'], { sizes: [35, 65] }))

      await getApiDoc()
      await nextTick(() => window.addEventListener('message', handleMessage))

      if (!isDev) {
        window.onbeforeunload = async () => {
          if (!isEditing.value) await handleUpdateApiDoc({ isEditing: false })
          return true
        }
      }

      spinVisible.value = false
    })

    onUnmounted(() => {
      window.removeEventListener('message', handleMessage)
    })

    return {
      isDev,
      spinVisible,
      apiDocList,
      selectedMenuDocKeys,
      selectedDocLink,
      getMenuDocTitle,
      handleMenuDocClick,
      handleSelectDoc,
      handlePublishAndSelectDoc,
      modalVisible,
      handleCloseModal,
      separateWindow,
      handleToggleSeparateWindow,
      handleDownload,
      handlePublish,
      isEditing,
      editorValue,
      handleEditorChange,
      handleEditorBlur,
      previewWrapperRef,
      iframeRef,
      previewerWinRef,
    }
  },
}
</script>

<style>
#app {
  height: 100%;
}

.openapi-editor {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 51px);
  overflow: hidden;
}

.selected-doc-link {
  color: #999;
}

.selected-doc-link:hover {
  color: #bbb;
  text-decoration: underline;
}

.editor-wrapper, .editor-wrapper .CodeMirror {
  font-size: 12px;
  height: 100%;
}

.editor-wrapper.readonly .CodeMirror {
  background: rgba(0, 0, 0, 0.25);
}

.preview-wrapper {
  height: 100%;
}

iframe.redoc-previewer {
  width: 100%;
  height: 100vh;
  border: none;
}

.gutter {
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: 50%;
}

.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAB3RJTUUH5QcRBxgaqu673QAAAAd0RVh0QXV0aG9yAKmuzEgAAAAMdEVYdERlc2NyaXB0aW9uABMJISMAAAAKdEVYdENvcHlyaWdodACsD8w6AAAADnRFWHRDcmVhdGlvbiB0aW1lADX3DwkAAAAJdEVYdFNvZnR3YXJlAF1w/zoAAAALdEVYdERpc2NsYWltZXIAt8C0jwAAAAh0RVh0V2FybmluZwDAG+aHAAAAB3RFWHRTb3VyY2UA9f+D6wAAAAh0RVh0Q29tbWVudAD2zJa/AAAABnRFWHRUaXRsZQCo7tInAAAAMElEQVQoke3MUQ0AMAwC0et8UnEYZQLWTEH5vJBXkgJgu7o7AIdhleSJ83PNNb/mBa1xRcn53XDRAAAAAElFTkSuQmCC');
  cursor: col-resize;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border-bottom: 3px solid #eee;
  background: #fafafa;
}

.hide {
  display: none;
}

.full-width {
  width: 100%;
}
</style>
